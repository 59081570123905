import React, { Suspense, useEffect } from 'react';
import { HashRouter as Router, Route, Routes, Navigate } from 'react-router-dom'; // Χρήση του HashRouter
import { useSelector } from 'react-redux';
import { CSpinner, useColorModes } from '@coreui/react';
import PropTypes from 'prop-types';
import './scss/style.scss';
import ForgotPassword from './views/pages/forgot-password/ForgotPassword';

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));
const IndividualLayout = React.lazy(() => import('./layout/IndividualLayout'));
const SalesLayout = React.lazy(() => import('./layout/SalesLayout'));
const UserLayout = React.lazy(() => import('./layout/UserLayout')); 

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const ProtectedRoute = ({ element, condition, redirectPath }) => {
  return condition ? element : <Navigate to={redirectPath} />;
};

ProtectedRoute.propTypes = {
  element: PropTypes.element.isRequired,
  condition: PropTypes.bool.isRequired,
  redirectPath: PropTypes.string.isRequired,
};

const App = () => {
  const { isColorModeSet, setColorMode } = useColorModes('coreui-free-react-admin-template-theme');
  const storedTheme = useSelector((state) => state.theme);
  const userRole = useSelector((state) => state.userRole);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.href.split('?')[1]);
    const theme = urlParams.get('theme') && urlParams.get('theme').match(/^[A-Za-z0-9\s]+/)[0];
    if (theme) {
      setColorMode(theme);
    }

    if (isColorModeSet()) {
      return;
    }

    setColorMode(storedTheme);
  }, []); 

  return (
    <Router>
      <Suspense
        fallback={
          <div className="pt-3 text-center">
            <CSpinner color="primary" variant="grow" />
          </div>
        }
      >
        <Routes>
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/" name="Login Page" element={<Login />} />
          <Route exact path="/register" name="Register Page" element={<Register />} />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route path="/individual-dashboard" name="Individual Dashboard" element={<IndividualLayout />} />
          <Route path="/portal/*" name="SalesLayout" element={<SalesLayout />} />
          <Route 
            path="/dashboard/*" 
            name="Dashboard" 
            element={
              <ProtectedRoute 
                element={<DefaultLayout />} 
                condition={userRole !== 'individual'} 
                redirectPath="/individual-dashboard" 
              />
            } 
          />
          <Route
            path="/user/*"
            name="User Dashboard"
            element={<UserLayout />}
          />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
